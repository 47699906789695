import React from 'react'
import { graphql, Link } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types'
import Breadcrumbs from '../components/ui/breadcrumbs'

import Layout from "../components/layout"
import Seo from '../components/seo'

const LongevitySessions = ({data, location}) => {
    const content = data.contentfulLongevitySessions.content
    const title = data.contentfulLongevitySessions.title
    const date = data.contentfulLongevitySessions.createdAt

    const previous = data.previous
    const next = data.next

    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
          },
          renderNode: {
            [INLINES.HYPERLINK]: (node, children) => {
                const { uri } = node.data
                const url = "http://" + uri
                return (
                  <a href={url} className="underline">
                    {children}
                  </a>
                )
              },
            [BLOCKS.HEADING_2]: (node, children) => {
              return <h2>{children}</h2>
            },
            [BLOCKS.HEADING_3]: (node, children) => {
              return <h3>{children}</h3>
            },
            [BLOCKS.PARAGRAPH]: (node, children) => {
              return <p>{children}</p>
            },
          },
      }

      return (
            <Layout location={location}>
                <Seo title={title} />
                <div className="container">
                    <Breadcrumbs loc="longevity sessions" subloc={title} />
                    <h1 style={{marginBottom: "0"}}>{title}</h1>
                    <p style={{marginBottom: "3rem", borderBottom: "3px solid hsla(243, 15%, 98%, 1)"}} className="date"><b>{date}</b></p>
                </div>
                <div className="container small">
                    {renderRichText(content, options)}
                </div>
                <div className="container small">
                    <div className="prev-next">
                        {previous && 
                            <Link className="button" to={`/longevity-sessions/${previous.slug}`} rel="prev">Previous</Link>
                        }
                        {next &&
                        <>
                            <div></div>
                            <Link className="button" to={`/longevity-sessions/${next.slug}`} rel="next">Next</Link>
                            </>
                        }         
                    </div>
                </div>
            </Layout>
          )
}

// export const sessionQuery = graphql`
//   {
//     allContentfulLongevitySessions {
//         nodes {
//           title
//           createdAt(formatString: "MM-DD-YYYY")
//           content {
//             raw
//           }
//         }
//       }
//   }
// `


export const sessionQuery = graphql`
  query LongevitySessionBySlug(
    $slug: String!
    $previousSessionSlug: String
    $nextSessionSlug: String
  ) {
    contentfulLongevitySessions(slug: { eq: $slug }) {
      id
      slug
      title
      createdAt(formatString: "MMMM Do, YYYY")
      content {
        raw
      }
    }
    previous: contentfulLongevitySessions(slug: { eq: $previousSessionSlug }) {
      slug
      title
    }
    next: contentfulLongevitySessions(slug: { eq: $nextSessionSlug }) {
      slug
      title
    }
  }
`


export default LongevitySessions


// import React from 'react'
// import { Link, graphql } from 'gatsby'
// import get from 'lodash/get'

// import Seo from '../components/seo'
// import Layout from '../components/layout'
// import Hero from '../components/hero'
// import Tags from '../components/tags'
// import * as styles from './blog-post.module.css'

// class LongevitySessionTemplate extends React.Component {
//   render() {
//     const post = get(this.props, 'data.contentfulLongevitySessions')
//     const previous = get(this.props, 'data.previous')
//     const next = get(this.props, 'data.next')

//     return (
//       <Layout location={this.props.location}>
//         <Seo
//           title={post.title}
//           description={post.description.childMarkdownRemark.excerpt}
//           image={`http:${post.heroImage.resize.src}`}
//         />
//         <Hero
//           image={post.heroImage?.gatsbyImageData}
//           title={post.title}
//           content={post.description?.childMarkdownRemark?.excerpt}
//         />
//         <div className={styles.container}>
//           <span className={styles.meta}>
//             {post.author?.name} &middot;{' '}
//             <time dateTime={post.rawDate}>{post.createdAt}</time> –{' '}
//             {post.body?.childMarkdownRemark?.timeToRead} minute read
//           </span>
//           <div className={styles.article}>
//             <div
//               className={styles.body}
//               dangerouslySetInnerHTML={{
//                 __html: post.body?.childMarkdownRemark?.html,
//               }}
//             />
//             <Tags tags={post.tags} />
//             {(previous || next) && (
//               <nav>
//                 <ul className={styles.articleNavigation}>
//                   {previous && (
//                     <li>
//                       <Link to={`/blog/${previous.slug}`} rel="prev">
//                         ← {previous.title}
//                       </Link>
//                     </li>
//                   )}
//                   {next && (
//                     <li>
//                       <Link to={`/blog/${next.slug}`} rel="next">
//                         {next.title} →
//                       </Link>
//                     </li>
//                   )}
//                 </ul>
//               </nav>
//             )}
//           </div>
//         </div>
//       </Layout>
//     )
//   }
// }

// export default LongevitySessionTemplate

// export const pageQuery = graphql`
//   query BlogPostBySlug(
//     $slug: String!
//     $previousPostSlug: String
//     $nextPostSlug: String
//   ) {
//     contentfulBlogPost(slug: { eq: $slug }) {
//       id
//       title
//       magazineName
//       createdAt(formatString: "MMMM Do, YYYY")
//       rawDate: publishDate
//       body {
//         childMarkdownRemark {
//           html
//           timeToRead
//         }
//       }
//       tags
//       description {
//         childMarkdownRemark {
//           excerpt
//         }
//       }
//     }
//     previous: contentfulBlogPost(slug: { eq: $previousPostSlug }) {
//       slug
//       title
//     }
//     next: contentfulBlogPost(slug: { eq: $nextPostSlug }) {
//       slug
//       title
//     }
//   }
// `
